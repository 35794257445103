.HomePage {
  display: flex;
  min-height: 90vh;
}

.LeftSide {
  background-color: #ffffff;
  flex: 35%;
  max-width: 35%;
  display: block;
  height: 0px;
}

.MuiInputBase-root.Mui-disabled {
  color: #adb5bd !important
}

.MuiFormHelperText-root {
  color: red !important;
}

.helperText {
  color: #c90e0e !important;
}
/* Common Button width and Button text Color*/
.ButtonColor {
  width: max-content !important;
  color: white !important;
}

/*To Handle FeedBack UI*/
#sticky {
  position: fixed;
  z-index: 1;
  margin-left: 94.5%;
  color:white !important;
  background-color:#0d2346 !important;
  top: 78%;
  border-radius: 50% !important;
  padding: 1%;
  border: none !important;
}

#centerOfPage {
  position: fixed;
  z-index: 1;
  margin-left: 95.4%;
  color:white !important;
  background-color:#0d2346 !important;
  top: 45%;
  border-radius: 50% !important;
  padding: 1%;
  border: none !important;
}
#overlap {
  position: fixed;
  z-index: 1;
  top: 12%;
  width: 35%;
  margin-left: 62%;           
}

.closeIconColor {
  color: aliceblue !important;
  /* margin-left: 90px !important;  */
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
}

/*Swal Alert Button Color*/
button.swal2-cancel.swal2-styled {
  background-color: #0d2346 !important
}

button.swal2-confirm.swal2-styled {
  background-color: #0d2346 !important;
}

.RightSide {
  background-color: #b2babe;
  border-left: 1px solid;
  max-width: 65%;
  max-height: 0vh;
  flex: 65%;
}

/* All Tab -> POST LOAD , COMMENT, PREFERRED CARRIER TAB*/
/* .MuiTab-wrapper {
  background-color: #0d2346;
  color: #f0f3f5;
  height: 41px;
  width: 9px;
  border-radius: 6px;
} */

@media screen and (min-width: 1300px) {
  .LeftSide {
    flex: 30%;

    max-width: 30%;
  }

  .RightSide {
    flex: 70%;

    max-width: 70%;
  }
}

.error input {
  border-bottom: 1px solid red;
  border-width: 0 0 1px;
}

.errors {
  border: 1px solid red;
  border-width: 0 0 1px;
  width: 140px;
}

.Discussion {
  background-color: #f0f3f5;
  display: flex;
  align-items: center;
  height: 82px;
  border-bottom: 2px solid whitesmoke;
  width: 100%;
}


.LeftDiscussion {
  height: 100%;
  padding: 0 15px 0 13px;
  display: flex;
  flex: none;
  align-items: center;
}

.RightDiscussion {
  display: flex;
  color: #0d2346;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RightDiscussionTop {
  display: flex;
}

.RightDiscussionTopLeft {
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RightDiscussionTopRight {
  font-size: 13px;
  font-weight: bold;
  color: #0d2346;
  white-space: nowrap;
}

.RightDiscussionBas {
  display: flex;
  color: #000000eb;
  font-size: 15px;
  height: 41px;
  white-space: nowrap;
}

.RightDiscussionBasLeft {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Custom-MuiListItem-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.Custom-MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 190px;
}

.datepicker-Box {
  outline: 0;
  border-width: 0 0 1px;
  width: 140px;
  background-color: #fafafa;

}

.datepicker-Box:hover {
  outline: 0;
  border-width: 0 0 2px;
  width: 140px;
  border-color: black;
}

.datepicker-Box:focus {
  border-width: 0 0 2px;
  border-color: blue;
  outline: 0;
}

fieldset.PrivateNotchedOutline-root-29075.MuiOutlinedInput-notchedOutline {
  border-color: #0d2346 !important
}

.MuiFormLabel-root.Mui-disabled {
  color: #0d2346 !important
}

/*Global all Font Color*/
div {
  color: #0d2346 !important
}

.col-sm {
  padding-right: 1px !important;
  color: #0d2346;
}

.Square {
  padding: 15px;
}

.Paper {
  height: 595px;
}

.Custom-MuiList-root {
  height: 600px;
}

.MuiBox-root {
  padding-top: 2px;
}

@media (min-width: 570px) and (max-width:820px) {
  .loadexp {
    display: block !important;
  }
}

label+.MuiInput-formControl {
  margin-top: 22px !important;
}

.col-6 {
  max-width: 100% !important
}


/* counter offer style */

.counteroffersubmit-btn {
  border: 2px solid green;
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
  margin-right: 5%;
}

.counteroffersubmit-btn:hover {
  background-color: green;
  color: white;
}

.counteroffersubmit-btn:focus {
  outline: none !important;
}

.counteroffercancel-btn:hover {
  background-color: red;
  color: white;
}

.counteroffercancel-btn:focus {
  outline: none !important;
}

.counteroffercancel-btn {
  border: 2px solid red;
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;

}

.counterofferaccept-btn:hover {
  background-color: orange;
  color: white;
}

.counterofferaccept-btn:focus {
  outline: none !important;
}

.counterofferaccept-btn {
  border: 2px solid orange;
  border-radius: 5px;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
}

.coediticon {
  font-size: 1rem !important;
}

.afterBidDisplay {
  margin-bottom: 0px !important;
  font-size: 14px !important;
  color: #101e35;
}

.Color {
  background-color: #ececec;
}

.MuiInput-root {
  font-size: 13px;
}

.MuiFormControl-root.MuiTextField-root {
  background-color: white;
}

.MuiSvgIcon-fontSizeInherit {
  color: white !important
}

.MuiAlert-message {
  color: white !important
}

.MuiSvgIcon-fontSizeSmall {
  color: white !important
}

/*Frieght Dimension and truck Dimension Close Icon*/
.CloseIcon {
  color: #101e35 !important
}

/*Load Expiration Box*/
.heightfix {
  width: 100%;
  background-color: #ececec;
  height: 145px;
  padding-top: 25px;
}

.h6 {
  color: #101e35;
}

/*Load Expiration Hour*/
.setCloseButtonSm3 {
  padding-left: 3% !important;
  padding-right: 0px !important;
}

/*Load Expiration Minute*/
.ExpirationButton {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hideClass {
  visibility: hidden;
}

.Bold {
  font-weight: bold;
}

.MuiTypography-h6 {
  color: #0d2346
}

.MUIDataTableBodyCell-root-1865.MUIDataTableBodyCell-cellHide-1866.MUIDataTableBodyCell-stackedHeader-1869.MUIDataTableBodyCell-stackedCommon-1870.MUIDataTableBodyCell-cellStackedSmall-1874 {
  color: #c90e0e
}

/* Load Detail -> (Stackable, hazmat, temperature Control, Dock High ) If Value Yes*/
.CorrectIcon {
  color: rgb(42, 226, 42)
}

/* Load Detail -> (Stackable, hazmat, temperature Control, Dock High ) If Value NA*/
.NotApplicable {
  color: red;
  margin-left: 5px !important;
}

button.MuiButtonBase-root.MuiIconButton-root {
  color: #101e35
}

.Warning {
  color: red !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  padding: 0px !important;
  background-color: white;
}

/*Bid Now Select Minute UI*/
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  height: 37px
}

.pin_btn {
  background-color: #f0f3f5;
  border: none;
}

@media only screen and (max-width: 1050px) {
  #loadexptime {
    max-width: 85%;
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1015px) {
  .MuiAutocomplete-endAdornment {
    right: -11px !important
  }

  #sticky {
    position: fixed;
    z-index: 1;
    margin-left: 93% !important;
    color:white !important;
    background-color:#0d2346 !important;
    padding: 1% !important;
    border-radius: 50% !important;
    border: none !important;
    top: 78%;
  }

  #centerOfPage {
    position: fixed;
    z-index: 1;
    margin-left: 93% !important;
    color:white !important;
    background-color:#0d2346 !important;
    padding: 1% !important;
    border-radius: 50% !important;
    border: none !important;
    top: 45%;
  }
  #overlap {
    position: fixed;
    z-index: 1;
    top: 13%;
    width: 40%;
    margin-left: 60%;           
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px) {
  .RightDiscussionTopLeft {
    font-size: 10px;
  }

  .RightDiscussionTopRight {
    font-size: 10px;
  }

  .RightDiscussionBas {
    font-size: 10px;
  }

  .rc-anchor-normal {
    width: 100px !important;
    margin-right: 240px !important;
  }

  #sticky {
    position: fixed;
    z-index: 1;
    margin-left: 93% !important;
    color:white !important;
    background-color:#0d2346 !important;
    padding: 1% !important;
    border-radius: 50% !important;
    border: none !important;
    top: 78%;
  }

  #centerOfPage {
    position: fixed;
    z-index: 1;
    margin-left: 93% !important;
    color:white !important;
    background-color:#0d2346 !important;
    padding: 1% !important;
    border-radius: 50% !important;
    border: none !important;
    top: 45%;
  }

  #overlap {
    position: fixed;
    z-index: 1;
    top: 13%;
    width: 40%;
    margin-left: 60%;           
  }

}

@media only screen and (max-width: 830px) {
  .RightDiscussionTopLeft {
    font-size: 7px;
  }

  .RightDiscussionTopRight {
    font-size: 7px;
  }

  .RightDiscussionBas {
    font-size: 7px;
  }

  #sticky {
    position: fixed;
    z-index: 1;
    margin-left: 88% !important;
    color:white !important;
    background-color:#0d2346 !important;
    padding: 1% !important;
    border-radius: 50% !important;
    border: none !important;
    top: 78%;
  }

  #centerOfPage {
    position: fixed;
    z-index: 1;
    margin-left: 88% !important;
    color:white !important;
    background-color:#0d2346 !important;
    padding: 1% !important;
    border-radius: 50% !important;
    border: none !important;
    top: 45%;
  }

  #overlap {
    position: fixed;
    z-index: 1;
    top: 12%;
    width: 65%;
    margin-left: 35%;           
  }

  .rc-anchor-normal {
    width: 100px !important;
    margin-right: 240px !important;
  }
}