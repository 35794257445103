.box {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 40px !important;
    background-color: white;
}

.MuiCardContent-root.CardContent{
    padding: 0 !important;
}

.Margin {
    margin-top: 15px;
    margin-left: 15px;
}
.Link {
    width: 20%;
    color: #101e35 !important;
    font-size : 110%;
    font-weight : bold;
}

.xs {
    padding: 5%;
}

svg.MuiSvgIcon-root.Icons {
    margin-right: 20px;
    height: 89px;
    width: 90px; 
}

.Icons {
    width: 25%;
    margin: 5%;
    border-radius: 10%;
}

.Card {
  width: 270px;
  margin-right: 10px;
  height: 90px;
}

.Number {
    font-size: 150%;
    font-weight: Bold;
}